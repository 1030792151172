@import '../../variables';

.Virez {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;

    .Main {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: white;
        height: 100%;
        width: 100%;

        .TopRadius {
            position: absolute;
            background-color: $second_color;
            bottom: 100%;
            right: 0;
        }

        .Top {
            position: absolute;
            background-color: white;
            bottom: 100%;
            right: 0;
        }

        .LeftRadius {
            position: absolute;
            background-color: $second_color;
            bottom: 0;
            right: 100%;
        }

        .Left {
            position: absolute;
            background-color: white;
            bottom: 0;
            right: 100%;
        }
    }

    &.theme {
        .Main {
            background-color: $second_color;
        }

        .TopRadius {
            background-color: white;
        }

        .Top {
            background-color: $second_color;
        }

        .LeftRadius {
            background-color: white;
        }

        .Left {
            background-color: $second_color;
        }
    }

    &.black {
        .Main {
            background-color: white;
        }

        .TopRadius {
            background-color: black;
        }

        .Top {
            background-color: white;
        }

        .LeftRadius {
            background-color: black;
        }

        .Left {
            background-color: white;
        }
    }

    &.Reverse {
        left: 0;

        .Main {
            left: 0;

            .TopRadius {
                left: 0;
            }

            .Top {
                left: 0;
            }

            .LeftRadius {
                left: 100%;
            }

            .Left {
                left: 100%;
            }
        }
    }
}