@import '../../variables';

.slider_container {
    overflow: auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    /* Задайте высоту по вашему усмотрению */
    position: relative;

    &::-webkit-scrollbar {
        height: 0;
    }
}

.slider_content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.SliderItem {
    flex: 0 0 auto;
    padding: 30px;
    min-width: 250px;
    // border-radius: $border_radius;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-out;
    position: relative;
    cursor: pointer;

    .top {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .rate {
            background-image: url("../../../public/images/like.svg");
            background-repeat: no-repeat;
            background-position: 0;
            background-size: 20px;
            padding-left: 30px;
            margin-bottom: 20px;
        }

        .arrow {
            height: 15px;
        }
    }

    .Logo {
        height: 30px;
    }

    .hovered {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: $border_radius;
        display: flex;
        align-items: center;
        justify-content: center;
        // backdrop-filter: blur(10px);
        opacity: 0;
        transition: opacity 0.3s ease-out;

        button {
            pointer-events: none;
            background-color: $theme_color;
            color: white;
            padding: 10px 15px;
            border-radius: 25px;
            transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
            transform: translateY(10px);

            @include enable_hover() {
                &:hover {
                    background-color: $hover_theme_color;
                }
            }
        }
    }

    @include enable_hover() {
        &:hover {
            .hovered {
                opacity: 1;

                button {
                    transform: translateY(0);
                    pointer-events: all;
                }
            }
        }
    }
}