@import '../../variables';

$left_width: 700px;
$image_height: 525px;

$main_color: $second_color;

@mixin Apartment {
    @media screen and (max-width: calc($left_width + 2 * $page_mobile_padding)) {
        @content;
    }
}

.NameBlock {
    margin-top: 50px;
}

.PageContent {
    width: $page_width;
    max-width: $page_max_width;
    padding-bottom: 100px;
    margin: 0 auto;
    position: relative;

    @include laptop() {
        margin-top: 75px;
    }
}

.Apartment {
    background-color: white;
}

.NameBlockMain {
    margin-top: 50px;
    width: $left_width;
    max-width: $page_max_width;
    display: flex;
    align-items: end;
    gap: 10px;

    @media screen and (max-width:550px) {
        .hide {
            display: none;
            flex-direction: row-reverse;
        }

        a:nth-child(2) {
            padding-right: 16px;
            background-image: url('../../../public/images/arrow_black_text.svg');
            background-size: 8px;
            background-position-y: calc(50% + 2px);
            background-position-x: calc(100% + 0px);
            background-repeat: no-repeat;
            cursor: pointer;

            @include tablet() {
                background-size: 7px;
                background-position-x: calc(100% - 2px);
            }
        }
    }

    .hide {
        opacity: 0.5;
    }

    a {
        @include enable_hover() {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    a:nth-child(1) {
        padding-right: 16px;
        background-image: url('../../../public/images/arrow_black_text.svg');
        background-size: 8px;
        background-position-y: calc(50% + 2px);
        background-position-x: calc(100% + 0px);
        background-repeat: no-repeat;
        cursor: pointer;

        @include tablet() {
            background-size: 7px;
            background-position-x: calc(100% - 2px);
        }
    }

}

.animation {
    transition: transform 0.7s ease-out, opacity 0.7s ease-out;

    &:not(.animation_show) {
        transform: translateY(50px);
        opacity: 0;
    }
}

.InfoBlock {
    margin-top: 20px;
    width: 100%;
    display: flex;
    gap: 75px;

    .Left {
        width: $left_width;

        .WrapperImagesBlock {
            position: relative;
        }

        .ImagesBlock {
            width: $left_width;
            height: $image_height;
            overflow-x: auto;
            overflow-y: hidden;
            scroll-snap-type: x mandatory;
            position: relative;
            border-radius: $border_radius;
            background-color: rgb(104, 104, 104);


            &::-webkit-scrollbar {
                height: 0px;
            }

            .ScrollItem {
                display: flex;

                .ImagesWrapper {
                    scroll-snap-align: start;
                    width: $left_width;
                    height: $image_height;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    position: relative;

                    .Image {
                        height: 100%;
                    }

                    .Background {
                        position: absolute;
                        width: 110%;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        filter: blur(8px) opacity(0.6);
                    }
                }
            }

            @include Apartment() {
                width: $page_max_width;
                height: calc($page_max_width * 3 / 4);

                .ScrollItem {
                    .ImagesWrapper {
                        width: $page_max_width;
                        height: calc($page_max_width * 3 / 4);
                    }
                }
            }

        }

        .buttonSwipe {
            position: absolute;
            height: 100%;
            width: 50px;
            background-color: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(2px);
            top: 0;
            background-image: url("../../../public/images/arrow_white.svg");
            background-size: 10px;
            background-position: 50%;
            background-repeat: no-repeat;
            transition: opacity 0.3s ease-out;
            border-top-right-radius: $border_radius;
            border-bottom-right-radius: $border_radius;

            &.hide {
                opacity: 0;
                pointer-events: none;
            }

            @include enable_hover() {
                &:hover {
                    background-color: rgba(220, 220, 220, 0.4);
                }
            }

            &.leftSwipe {
                left: 0;
                transform: rotate(180deg);
            }

            &.rightSwipe {
                right: 0;
            }
        }
    }

    @include laptop() {
        justify-content: center !important;
    }
}

.BookWrapper {
    position: sticky;
    align-self: flex-start;
    top: 25px;
    width: 350px;
    transition-delay: 0.2s;

    @include laptop() {
        display: none;
    }
}

.AboutBlock {
    margin-top: 50px;

    @include mobile() {
        margin-top: 40px;
    }

    width: $left_width;
    max-width: $page_max_width;
    padding: 30px;
    border-radius: $border_radius;
    background-color: $main_color;
    display: flex;
    justify-content: space-between;

    .ShortInfo {
        display: flex;
        flex-direction: column;
        align-items: start;

        .Icon {
            height: 26px;
        }

        .Bold {
            margin-top: 40px;
        }

        .List {
            margin-top: 15px;

            ul {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
        }
    }

    .infoText {
        .hide {
            color: #757575;
        }
    }

    .More {
        align-self: end;
        padding: 10px 15px;
        border: 1px solid $theme_color;
        color: $theme_color;
        border-radius: 20px;

        @include enable_hover() {
            &:hover {
                background-color: $theme_color;
                color: white;
            }
        }
    }
}

.RulesBlock {
    margin-top: 50px;

    @include mobile() {
        margin-top: 40px;
    }

    padding: 30px;
    background-color: $main_color;
    border-radius: $border_radius;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: $left_width;
    max-width: $page_max_width;

    .BigBR {
        margin-bottom: 8px;
    }

    .icon {
        margin-bottom: 30px;
        height: 26px;
    }

    .Bold {
        margin-bottom: 10px;
        display: inline-block;
        margin-top: 20px;
    }
}

.Contacts {
    background-color: $main_color;
    border-radius: $border_radius;
    padding: 30px;
    margin-top: 50px;

    @include mobile() {
        margin-top: 40px;
    }

    width: $left_width;
    max-width: $page_max_width;

    .Top {
        display: flex;
        flex-direction: column;
        align-items: start;

        .icon {
            height: 26px;
            margin-bottom: 40px;
        }

        .Text {
            margin-Top: 15px;
        }

        .ContactsList {
            display: flex;
            flex-direction: column;
            gap: 15px;

            li {
                background-repeat: no-repeat !important;
                background-size: 17px;
                background-position: 0px;
                padding-left: 23px;
            }

            .phone {
                background-image: url("../../../public/images/phone_black.svg");
            }

            .tg {
                background-image: url("../../../public/images/tg_black.svg");
            }

            .mail {
                background-image: url("../../../public/images/mail_black.svg");
            }
        }
    }

    .bottom {
        width: 100%;
        display: flex;
        justify-content: end;
        gap: 10px;

        img {
            height: 35px;
            left: 0;
        }
    }
}

.ReveiwsBlock {
    padding: 100px 0 100px 0;
    background-color: $main_color;
    display: flex;
    flex-direction: column;
    align-items: center;

    .TopBlock {
        width: $page_width;
        max-width: $page_max_width;
        // padding-left: calc((100vw - $page_width) / 2);

        .Info {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: start;

            img {
                height: 26px;
            }

            span {
                max-width: 700px;
                margin-top: 15px;
                margin-bottom: 40px;
            }
        }
    }

    .Content {}

    .Reccomend {
        width: $page_width;
        max-width: $page_max_width;
        margin-top: 40px;
    }
}

.BookMobileWrapper {
    position: absolute;
    pointer-events: none;
    height: calc(100% - 25px);
    width: calc(100% + 2 * $page_mobile_padding);
    left: -$page_mobile_padding;
    top: -75px;
    display: none;

    .BookButtonMobile {
        pointer-events: all;
        position: sticky;
        align-self: flex-start;
        top: -1px;
        z-index: 1;
        background-color: $theme_color;
        width: 100%;
        height: 50px;
        color: white;
        display: block;

        @include enable_hover() {
            &:hover {
                background-color: $hover_theme_color;
            }
        }
    }

    @include laptop() {
        display: flex;
    }
}

.WrapperFullService {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #cdcdcd77;
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: fadeIn 0.3s ease-out forwards;

    .Inner {
        width: 350px;
        max-width: $page_max_width;
    }
}