@import '../../variables';

.Infra {
    width: 400px;
    background-color: $second_color;
    border-radius: $border_radius;
    display: flex;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transform: translateY(20px);
    /* Элемент немного смещён вниз */
    animation: fadeIn 0.5s ease-out forwards;

    /* Анимация длится 1 секунду */
    .Content {
        padding: 30px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: start;

        img {
            height: 30px;
        }

        .Name {
            margin-top: 40px;
        }

        .DescrWrapper {
            position: relative;

            .Descr {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                margin-top: 15px;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                line-clamp: 3;
                /* Показывает 3 строки */
                -webkit-line-clamp: 3;

                &.Expdanded {
                    overflow: visible;
                    text-overflow: clip;
                    line-clamp: initial;
                    /* Показывает 3 строки */
                    -webkit-line-clamp: initial;
                    margin-bottom: 15px;

                    &::after {
                        content: '';
                        background: transparent !important;
                    }
                }

                &.NeedExpand {
                    &::after {
                        content: '';
                        /* Псевдоэлемент для отступа */
                        background-color: $second_color;
                        position: absolute;
                        border-radius: 50%;
                        bottom: 0;
                        right: 0;
                        width: 25px;
                        /* Ширина отступа, куда будет помещаться кнопка */
                        height: 20px;
                    }
                }
            }

            .Expand {
                position: absolute;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background-color: green;
                right: 0px;
                bottom: 0;
                background-color: $grey_button2;

                background-image: url("../../../public/images/arrow_black.svg");
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: 50%;
                background-position-y: calc(50% + 1px);
                transform: rotate(-90deg);

                &.Expanded {
                    transform: rotate(180deg);
                    bottom: -10px;
                }

                @include enable_hover() {
                    &:hover {
                        background-color: $hover_grey_button_color;
                    }
                }
            }
        }

    }

    .ImageWrappes {
        width: calc(100% - 60px);
        height: 229px;
        border-radius: $border_radius;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 120px;
        img {
            height: 105%;
        }
    }

    .Map {
        position: absolute;
        display: block;
        color: white;
        right: 0px;
        bottom: 0px;
        z-index: 1;
        .ContentButton {
            display: block;
            right: 0;
            bottom: 0;
            padding: 20px;
            margin-top: auto;
            color: $theme_color;
            // background-color: $theme_color;
            border: 2px solid $theme_color;
            border-radius: $border_radius;

            // background-image: url("../../../public/images/arrow_theme.svg");
            // background-position: 50%;
            // background-repeat: no-repeat;
            // background-size: 15%;
            @include enable_hover() {
                &:hover {
                    background-color: $hover_theme_color;
                    color: white;
                }
            }
        }
    }

    @include small_mobile () {
        width: $page_max_width;
        .Content {
            padding: 20px;
        }
        .ImageWrappes{
            width: calc(100% - 40px);
            height: calc(($page_max_width - 40px) * 3 / 4);
        }
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}